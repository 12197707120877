import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Slide from "./Slide/Slide";

import "swiper/css";
import "swiper/css/pagination";
import classes from "./Slider.module.scss";

const Slider = ({sliderItems}) => {
    return(
        <div className={classes.Slider}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                centeredSlides={true}
                pagination={{
                    clickable: true
                }}
                breakpoints={{
                    576: {
                    slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                    992: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 2,
                    },
                    1600: {
                        slidesPerView: 3,
                    },
                }}
                modules={[Pagination]}
                className={classes.mySwiper}
            >
                {
                    sliderItems.map((post) => {
                        return(
                            <SwiperSlide key={post.id}>
                                <Slide
                                    image={post.acf.thumbnail}
                                    title={post.title.rendered}  
                                    text={post.excerpt.rendered}
                                    link={{
                                        url: `/news/${post.slug}`,
                                        target: "_self",
                                        rel: "",
                                    }}         
                                />
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div>
    )
}

export default Slider;