import React from "react";

import classes from "./CategorySubtitle.module.scss";

const CategorySubtitle = (props) => {
    return(
        <p className={classes.subtitle} dangerouslySetInnerHTML={{ __html: props.children }}></p>
    );
}

export default CategorySubtitle;