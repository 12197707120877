import React from "react";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg"; 
import usePagination, { DOTS } from "../../common/usePagination";

import classes from "./Pagination.module.scss";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;


    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (parseInt(currentPage) === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        if(parseInt(currentPage) < totalCount) {
            onPageChange(parseInt(currentPage) + 1);
        }
    };

    const onPrevious = () => {
        if(parseInt(currentPage) > 1) {
            onPageChange(parseInt(currentPage) - 1);
        }
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return(
        <ul className={classes.pagination}>
            <li 
                className={parseInt(currentPage) === 1 ? classes.disabled : ''}
                onClick={onPrevious}>
                <span className={[classes.button, classes.buttonPrev].join(' ')}><Arrow/></span>
            </li>
            {
                paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <li key={index} className={classes.dots}>
                            <span>&#8230;</span>
                        </li>
                    }

                    return(
                        <li
                            key={index}
                            className={pageNumber === parseInt(currentPage) ? classes.selected : ''}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            <span>{pageNumber}</span>
                        </li>
                    )
                })
            }

            <li
                className={parseInt(currentPage) === lastPage ? classes.disabled : ''}
                onClick={onNext}
            >
                <span className={[classes.button, classes.buttonNext].join(' ')}><Arrow/></span>
            </li>
        </ul>
    );
}

export default Pagination;