import React from "react";
import { heroContent } from "../../../config/content";
import MyLink from "../../UI/MyLink/MyLink";

import classes from "./SectionHeroContent.module.scss";

const SectionHeroContent = (props) => {
    return(
        <div className={classes.content}>
            <div className="wrapper">
                <div className={classes.contentContainer}>
                    <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: heroContent.title }}></h1>
                    <h4 className={classes.text}>{ heroContent.subtitle }</h4>
                    <MyLink
                        linkType="buttonType"
                        href="#contacts"
                        outterClasses={classes.button}
                    >
                        Get In Touch
                    </MyLink>
                </div>
            </div>
        </div>
    );
}

export default SectionHeroContent;