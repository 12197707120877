import React from 'react';

import classes from './Hamburger.module.scss'

const Hamburger = (props) => {
    return(
        <button
            onClick={props.handleClick}
            className={classes.Hamburger}
        >
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
}

export default Hamburger;