import React from "react";
import { socialsConfig } from "../../../config/socialsConfig";
import ContactLine from "../ContactLine/ContactLine";
import Image from "../Image/Image";

import classes from "./Thumb.module.scss";

const Thumb = ({imageSrc, alt, ...props}) => {
    return(
        <div className={classes.thumb}>
            <Image
                outterClass={classes.imgWrapper}
                src={imageSrc}
                alt={alt}
            />
            <div className={classes.contactBlock}>
                <ContactLine href={`mailto:${socialsConfig.email}`} type="email" />
            </div>
        </div>
    )
}

export default Thumb;