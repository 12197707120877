import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import CategoryHead from "../../Components/CategoryHead/CategoryHead";
import NewsList from "../../Components/NewsList/NewsList";

const CareerPage = (props) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const pageID = searchParams.get('page');
    const queryString = [
        `page=${pageID ? pageID : props.pageID}`,
        `&per_page=${5}`,
    ].join('');
    

    return(
        <>
            <Helmet>
                <title>Delphi - Career</title>
                <link rel="canonical" href={`https://www.udelphi.com${location.pathname}`} />
            </Helmet>
            <div className="wrapper page">
                <Breadcrumbs showCrumb={props.showCrumb} />
                <div className="flex-container">
                    <div className="flex-xl-5 flex-md-12">
                        <CategoryHead
                            title="Career"
                            description="Join Our Team"
                        >
                            <img src={require('../../assets/images/imageCareerHead.svg').default} alt="Career"/>
                        </CategoryHead>
                    </div>
                    <div className="flex-xl-7 flex-md-12">
                        <NewsList
                            postsParams={{
                                pageID: pageID ? pageID : props.pageID,
                                postType: "jobs",
                                navSlug: 'career',
                            }}
                            queryString={queryString}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CareerPage;