import React from "react";
import Image from "../../UI/Image/Image";
import ReadMoreLink from "../../UI/ReadMoreLink/ReadMoreLink";

import classes from "./ServicesItem.module.scss";

const ServicesItem = ({imageSrc, title, descr, linkUrl, linkText, ...props}) => {
    return(
        <a
            href={linkUrl}
            className={[classes.item, props.outterClasses].join(' ')}>
            <Image
                outterClass={classes.iconWrapper}
                src={imageSrc}
                alt={title}
            />
            <h5 className={classes.title} dangerouslySetInnerHTML={{ __html: title }}></h5>
            <p className={classes.descr} dangerouslySetInnerHTML={{ __html: descr }}></p>
            <ReadMoreLink
                outterClasses={classes.link}
                isFake={true}
            >
                {linkText ? linkText : 'Go to projects'}
            </ReadMoreLink>
        </a>
    );
}

export default ServicesItem;