import React from "react";
import InfographicItem from "./InfographicItem/InfographicItem";

const Infographic = ({items}) => {
    return (
        <div className="flex-container">
            {
                items.map((item, index) => (
                    <div key={index} className="flex-xl-4 flex-md-6 flex-sm-12">
                        <InfographicItem
                            imageSrc={item.image}
                            title={item.title}
                            text={item.subtitle} 
                        />
                    </div>
                ))
            }
        </div>
    );
}

export default Infographic;