import React from "react";
import ServicesItem from "./ServicesItem/ServicesItem";

import classes from "./ServicesList.module.scss";

const ServicesList = ({items}) => {
    return(
        <div className={classes.list}>
            <div className="flex-container">
                {
                    items.map((item) => {
                        if(item.slug !== "uncategorized") {
                            return(
                                <div key={item.id} className={["flex-xl-4 flex-md-6 flex-xs-12", classes.listItem].join(' ')}>
                                    <ServicesItem
                                        imageSrc={item.acf.icon.url}
                                        title={item.name}
                                        descr={item.acf.description}
                                        linkUrl={`/services/${item.slug}`}
                                    />
                                </div>
                            );
                        }
                        return null;
                    })
                }
            </div>
        </div>
    );
}

export default ServicesList;