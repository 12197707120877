import React from "react";
import { Link } from "react-router-dom";
import Location from "../../UI/Location/Location";

import classes from "./Slide.module.scss";

const SlideJobs = (props) => {
    const {title, text, location, link } = props;

    return(
        <Link
            to={link.url}
            target={link.target}
            rel={link.rel}
            className={classes.Slide}
        >
            <div className={classes.content}>
                <h6 className={classes.title} dangerouslySetInnerHTML={{ __html: title }}></h6>
                <Location>{location}</Location>
                <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
        </Link>
    )
}

export default SlideJobs;