import React from "react";
import { ReactComponent as Icon } from "../../../assets/images/iconEmail.svg";

import classes from "./ContactLine.module.scss";

const ContactLine = (props) => {
    return(
        <div className={[classes.line, props.type].join(' ')}>
            <Icon />
            <span>Please <a href={props.href}>contact us</a>, if you need more information</span>
        </div>
    );
}

export default ContactLine;