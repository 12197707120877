import React, { useContext } from "react";
import { AppStore } from "../../common/appStore";
import { contactsContent } from "../../config/content";
import ContactForm from "../ContactForm/ContactForm";
import SectionHead from "../SectionHead/SectionHead";
import Image from "../UI/Image/Image";
import TextBox from "../UI/TextBox/TextBox";

import classes from "./SectionContacts.module.scss";

const SectionContacts = (props) => {
    const { isMobile } = useContext(AppStore);

    return(
        <section className={classes.section} id="contacts">
            <div className="wrapper">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <SectionHead
                            title={contactsContent.title}
                            subtitle={contactsContent.subtitle}
                        />
                    </div>
                    {
                        isMobile &&
                            <div className="flex-md-12">
                                <TextBox>
                                    <div className={classes.mobileTextBox} dangerouslySetInnerHTML={{ __html: contactsContent.text }}></div>
                                </TextBox>
                            </div>
                    }
                    <div className="flex-xl-4 flex-lg-5 flex-md-6 flex-sm-12">
                        <ContactForm />
                    </div>
                    {
                        !isMobile &&
                            <div className="flex-xl-3 flex-lg-4">
                                <TextBox>
                                    <div dangerouslySetInnerHTML={{ __html: contactsContent.text }}></div>
                                </TextBox>
                            </div>
                    }
                    <div className="flex-xl-5 flex-lg-3 flex-md-6 flex-sm-12">
                        <Image
                            src={contactsContent.image}
                            alt={contactsContent.title}
                            outterClass={classes.imgWrapper}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionContacts;