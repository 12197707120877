import React from "react";
import { Link } from "react-router-dom";

import {ReactComponent as Logo} from "../../../assets/images/Logo.svg";

import classes from "./HeaderLogo.module.scss";

const HeaderLogo = (props) => {
    return(
        <Link to="/" className={classes.logo}>
            <Logo />
        </Link>
    );
}

export default HeaderLogo;