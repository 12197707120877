import React, { useState } from "react";
import useFormFields from "../../common/useFormFields";
import Input from "../UI/Input/Input";
import TextArea from "../UI/TextArea/TextArea";
import Button from "../UI/Button/Button";

import classes from "./ContactForm.module.scss";
import myAxios from "../../config/axiosConfig";

const ContactForm = (props) => {
    const { fields , changeFieldValue } = useFormFields({
        fullName: '',
        email: '',
        subject: 'Delphi Site - Contact Form',
        message: ''
    });
    const [formResponse, setFormResponse] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = new FormData();

        for (const field in fields) {
            form.append(field, fields[field]);
        }

        try {
            const response = await myAxios.post('/wp-json/contact-form-7/v1/contact-forms/147/feedback', form, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            setFormResponse(response);
            setIsLoaded(true);
            console.log(response);
        } catch(err) {
            if(err.response) {
                // Not on 200 response range
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);

                setFormResponse(err.response);
            } else {
                console.log(`Error: ${err.message}`);
                setFormResponse(err);
            }
        }
    };

    return(
        <form
            className={classes.form}
            onSubmit={handleSubmit}
        >
            <Input
                type="text"
                name="fullName"
                value={fields.fullName}
                placeholder="Name"
                onChange={changeFieldValue}
                required
            />
            <Input
                type="email"
                name="email"
                value={fields.email}
                placeholder="Email"
                onChange={changeFieldValue}
                required
            />
            <TextArea 
                name="message"
                value={fields.message}
                placeholder="Message"
                onChange={changeFieldValue}
                required
            />
            <Button type="submit">
                Get in touch
            </Button>
            {
                isLoaded &&
                    <div 
                        className={classes.message}
                        data-status={formResponse.data.status}
                    >
                        {formResponse.data.message}
                    </div>
            }
        </form>
    );
}

export default ContactForm;