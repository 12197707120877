import React from "react";
import { Link } from "react-router-dom";
import ReadMoreLink from "../../UI/ReadMoreLink/ReadMoreLink";
import Image from "../../UI/Image/Image";

import classes from "./NewsItem.module.scss";
import Location from "../../UI/Location/Location";

const NewsItem = (props) => {
    const { image, link, title, subtitle, iconThumb } = props;

    return(
        <article className={[
            classes.item,
            (image ? classes.hasImage : ''),
            (iconThumb ? classes.hasIcon : ''),
        ].join(' ')}>
            <Link
                className={classes.itemLink}
                to={link}
            >
                {
                    image !== null
                    ? <Image
                        outterClass={[classes.imgWrapper, iconThumb ? classes.icon : '' ].join(' ')}
                        src={
                            (image.sizes && image.sizes.post_thumb_slider)
                            ? image.sizes.post_thumb_slider
                            :  image.url
                        }
                        alt={image.alt}
                    /> : null
                }
                <div className={[
                    classes.content, 
                    (!iconThumb ? classes.smallText : ''),
                ].join(' ')}>
                    <h4 className={classes.title} dangerouslySetInnerHTML={{ __html: title }}></h4>
                    {
                        props.city
                        ? <div className={classes.location}><Location>{props.city}</Location></div>
                        : null
                    }
                    <div className={classes.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}></div>
                    <ReadMoreLink
                        outterClasses={classes.readMore}
                        isFake="true"
                    >Read More</ReadMoreLink>
                </div>
            </Link>
        </article>
    );
}

export default NewsItem;