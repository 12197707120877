import React from "react";

import classes from "./CategoryTitle.module.scss";

const CategoryTitle = (props) => {
    return(
        <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: props.children }}></h1>
    );
}

export default CategoryTitle;