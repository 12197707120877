import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/UI/Button/Button";

import classes from "./NogFoundPage.module.scss";

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate("/");
    }

    return(
        <>
            <Helmet>
                <title>Delphi - Page Not Found</title>
                <link rel="canonical" href={`https://www.udelphi.com/`} />
            </Helmet>
            <main className="main">
                <div className="wrapper">
                    <div className={classes.notFound}>
                        <h1>404</h1>
                        <p>Page Not Found</p>
                        <Button
                            onClick={handleClick}
                        >Go Back to Home</Button>
                    </div>
                </div>
            </main>
        </>
    );
}

export default NotFoundPage;