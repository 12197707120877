import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import useFetch from "../../common/useFetch";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import CategoryHead from "../../Components/CategoryHead/CategoryHead";
import NewsList from "../../Components/NewsList/NewsList";

const ServicePage = (props) => {
    const params = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [categories, isLoaded] = useFetch(`wp-json/wp/v2/categories?slug=${params.category}`);
    const pageID = searchParams.get('page');
    const [categoryInfo, setCategoryInfo] = useState({
        id: 0,
        title: '',
        subtitle: '',
        image: '',
    });

    useEffect(() => {
        if(isLoaded) {
            setCategoryInfo({
                id: categories.data[0].id,
                title: categories.data[0].name,
                subtitle: categories.data[0].acf.description,
                image: categories.data[0].acf.image.url,
            });
        }
    }, [categories, isLoaded, params.category])

    const queryString = [
        `page=${pageID ? pageID : props.pageID}`,
        `&per_page=${5}`,
        `&categories=${categoryInfo.id}`,
    ].join('');

    return(
        <>
            <Helmet>
                <title>{`Delphi - ${categoryInfo.id ? categoryInfo.title : 'Not Found' }`}</title>
                <link rel="canonical" href={`https://www.udelphi.com${location.pathname}`} />
            </Helmet>
            <div className="wrapper page">
                <Breadcrumbs showPostType={true} />
                <div className="flex-container">
                    {
                        categoryInfo.id
                        ? <div className="flex-xl-5 flex-md-12">
                            <CategoryHead
                                title={categoryInfo.title}
                                description={categoryInfo.subtitle}
                                imageSrc={categoryInfo.image}
                            />                       
                        </div>
                        : null
                    }
                    <div className={ categoryInfo.id ? 'flex-xl-7 flex-md-12' : 'flex-xl-12'}>                                     
                        {
                            categoryInfo.id
                            ? <NewsList
                                postsParams={{
                                    pageID: pageID ? pageID : props.pageID,
                                    postType: "services",
                                    categoryID: categoryInfo.id,
                                    navSlug: `services/${params.category}`,
                                }}
                                queryString={queryString}
                                iconThumb={true}
                            /> : null
                        }                     
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServicePage;