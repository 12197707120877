import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageMenu from "../ImageMenu/ImageMenu";
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg";

import classes from "./SubMenu.module.scss";

const MenuItems = ({item, handleDropDown}) => {
    const [innerNav, setInnerNav] = useState(false);

    const onMouseEnter = () => {
        setInnerNav(true);
    };
    
    const onMouseLeave = () => {
        setInnerNav(false);
    };

    return(
        <li
            className={item.inner_menu_items && classes.hasSubMenu}
            onMouseEnter={item.inner_menu_items && onMouseEnter}
            onMouseLeave={item.inner_menu_items && onMouseLeave}
            onClick={handleDropDown}
        >
            <Link to={item.path}>{item.name} <Arrow /></Link>
            {
                item.inner_menu_items
                ? <ImageMenu
                    menu={item.inner_menu_items} 
                    isShown={innerNav}
                />
                : null
            }
        </li>
    );
}


export default MenuItems;