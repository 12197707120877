import React from "react";

import { ReactComponent as Arrow } from "../../../assets/images/arrowNext.svg";
import classes from "./ReadMoreLink.module.scss";

const ReadMoreLink = ({ outterClasses, children, href, isFake, ...props }) => {
    return (
        !isFake
        ? <a className={[classes.link, outterClasses].join(' ')} href={href} {...props} >{children}<Arrow /></a>
        : <span className={[classes.link, outterClasses].join(' ')} {...props} >{children}<Arrow /></span>
    );
}

export default ReadMoreLink;