import React from "react";

import classes from "./Input.module.scss";

const Input = (props) => {
    return(
        <div className={classes.boxField}>
            <input
                {...props}
                className={classes.formControl}
            />
        </div>
    );
}

export default Input;