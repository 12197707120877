import React from "react";
import Image from "../../UI/Image/Image";

import classes from "./InfographicItem.module.scss"

const InfographicItem = ({imageSrc, title, text}) => {
    return (
        <div className={classes.Item}>
            <Image
                outterClass={classes.imgWrapper}
                src={imageSrc}
                alt={text}
            />
            <div className={classes.content}>
                <div className={classes.content__title}>
                    {title}
                </div>
                <div className={classes.content__text}>
                    {text}
                </div>
            </div>
        </div>
    );
}

export default InfographicItem;