import React from "react";

import classes from "./SectionHero.module.scss"
import SectionHeroContent from "./SectionHeroContent/SectionHeroContent";
import SectionHeroSlider from "./SectionHeroSlider/SectionHeroSlider";

const SectionHero = (props) => {
    return (
        <section className={classes.section}>
            <SectionHeroContent />
            <SectionHeroSlider />
        </section>
    )
}

export default SectionHero;