export const footerMenuItems = [
    [
        {
            title: "About",
            url: "/#about",
        },
        {
            title: "News",
            url: "/news",
        },
        {
            title: "Focus",
            url: "/focus",
        },
        {
            title: "Career",
            url: "/career",
        },
    ],
    [
        {
            title: "Digital Enterprise",
            url: "/services/digital-enterprise",
        },
        {
            title: "Embedded & Networking",
            url: "/services/embedded-networking",
        },
        {
            title: "Natural Language Solutions",
            url: "/services/natural-language-solutions",
        },
        {
            title: "Web",
            url: "/services/web",
        },
    ],
    [
        {
            title: "Cloud",
            url: "/services/cloud",
        },
        {
            title: "Quality Assurance",
            url: "/services/quality-assurance",
        },
        {
            title: "Fintech",
            url: "/services/fintech",
        },
        {
            title: "DevOps",
            url: "/services/devops",
        },
    ],
    [
        {
            title: "Data Management",
            url: "/services/data-management",
        },
        {
            title: "Mobile",
            url: "/services/mobile",
        },
    ],
];