import React from "react";
import SectionSubTitle from "../UI/SectionSubTitle/SectionSubTitle";
import SectionTitle from "../UI/SectionTitle/SectionTitle";

import classes from "./SectionHead.module.scss";

const SectionHead = (props) => {
    const {title, subtitle} = props;

    return(
        <div className={classes.sectionHead}>
            <div className={classes.title}>
                <SectionTitle>{title}</SectionTitle>
            </div>
            <div className={classes.subtitle}>
                <SectionSubTitle>{subtitle}</SectionSubTitle>
            </div>
        </div>
    );
}

export default SectionHead;