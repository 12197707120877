import React from "react";
import SectionHero from "../../Components/SectionHero/SectionHero";
import SectionPartners from "../../Components/SectionPartners/SectionPartners";
import SectionAbout from "../../Components/SectionAbout/SectionAbout";
import SectionServices from "../../Components/SectionServices/SectionServices";
import SectionContacts from "../../Components/SectionContacts/SectionContacts";
import SectionJobs from "../../Components/SectionJobs/SectionJobs";
import SectionNews from "../../Components/SectionNews/SectionNews";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
    return(
        <>
            <Helmet>
                <title>DELPHI SOFTWARE | INSPIRED BY INNOVATIONS</title>
                <link rel="canonical" href="https://www.udelphi.com/" />
            </Helmet>
            <SectionHero />
            <SectionPartners />
            <SectionAbout />
            <SectionServices />
            <SectionContacts />
            <SectionJobs />
            <SectionNews />
        </>
    );
}

export default HomePage;