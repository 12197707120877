import React from "react";
import MyRoutes from "../../Routes";

import classes from "./ContentContainer.module.scss";

const ContentContainer = (props) => {
    return(
        <main className={classes.main}>
            <MyRoutes location={props.location}/>
        </main>
    );
}

export default ContentContainer;