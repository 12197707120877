import React from "react";
import MenuItems from "./MenuItems";

import classes from "./SubMenu.module.scss";

const SubMenu = ({ submenus, dropdown, handleDropDown }) => {
    return(
        <ul className={[classes.SubMenu, dropdown ? classes.shown : ""].join(' ')}>
            {
                submenus.map((item, index) => {
                    return(
                        <MenuItems key={index} item={item} handleDropDown={handleDropDown}/>
                    )
                })
            }
        </ul>
    );
}

export default SubMenu;