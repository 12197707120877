import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import classes from "./NavMenu.module.scss";
import SubMenu from "./SubMenu/SubMenu";
// import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";

const MenuItems = ({item, ...props}) => {
    let ref = useRef();
    const [dropdown, setDropdown] = useState(false);
    const {servicesItems, isLoadedServices} = props;

    const handleDropDown = () => setDropdown((prev) => !prev);

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    return(
        <li 
            className={item.submenu ? classes.hasSubMenu : ''}
            ref={ref}
        >
            {
                item.submenu && item.title === 'Services'
                ? (
                    <>
                        <button
                            type="button"
                            aria-haspopup="menu"
                            aria-expanded={dropdown ? "true" : "false"}
                            onClick={handleDropDown}
                        >
                            {item.title}
                            {/* <Arrow /> */}
                        </button>
                        
                        {
                            isLoadedServices &&
                            <SubMenu
                                dropdown={dropdown}
                                handleDropDown={handleDropDown}
                                submenus={servicesItems.data[0].acf.inner_menu_items}
                            />
                        }
                    </>
                )
                : ( <Link   
                        to={item.url}
                    >{item.title}</Link> )
            }
        </li>
    );
}

export default MenuItems;