export const menuItems = [
    {
        title: "About",
        url: "/#about",
    },
    {
        title: "Services",
        url: "/services",
        submenu: true
    },
    {
        title: "News",
        url: "/news"
    },
    {
        title: "Focus",
        url: "/focus"
    },
    {
        title: "Career",
        url: "/career"
    },
];