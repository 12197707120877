import React from "react";
import useFetch from "../../common/useFetch";
import { menuItems } from "../../config/menuItems";
import MenuItems from "./MenuItems";

import classes from "./NavMenu.module.scss";

const NavMenu = (props) => {
    const [servicesItems, isLoadedServices] = useFetch('/wp-json/wp/v2/menu?slug=services-sub-menu');

    return(
        <nav className={classes.NavMenu}>
            <ul>
                {
                    menuItems.map((item, index) => {
                        return(
                            <MenuItems 
                                key={index} 
                                item={item}
                                servicesItems={servicesItems}
                                isLoadedServices={isLoadedServices}
                            />
                        );
                    })
                }
            </ul>
        </nav>
    );
}

export default NavMenu;