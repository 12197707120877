import React from "react";

import classes from "./TextArea.module.scss";

const TextArea = (props) => {
    return(
        <div className={classes.boxField}>
            <textarea
                {...props}
                className={classes.textAreaControl}
            ></textarea>
        </div>
    );
}

export default TextArea;