export const getUnicArray = (array) => {
    const modifiedArray = [];

    for (let str of array) {
        if (!modifiedArray.includes(str)) {
            modifiedArray.push(str);
        }
    }

    return modifiedArray;
}

export const isMobile = () => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    }
    return false;
}

export const handleBodyScroll = (isModalOpen) => {
    if(isModalOpen) {
        document.body.classList.add('blocked');
    } else {
        document.body.classList.remove('blocked');
    }
}