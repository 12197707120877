import React from "react";

import classes from "./SectionSubTitle.module.scss";

const SectionSubTitle = (props) => {
    return(
        <h3 className={classes.title}>{props.children}</h3>
    );
}

export default SectionSubTitle;