import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import ServicePage from "../Pages/ServicePage/ServicePage";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import InnerPage from "../Pages/InnerPage/InnerPage";
import NewsPage from "../Pages/NewsPage/NewsPage";
import CareerPage from "../Pages/CareerPage/CareerPage";
import FocusPage from "../Pages/Focus/FocusPage";

const MyRoutes = (props) => {
    return (
        <Routes location={props.location}>
            <Route exact path="/">
                <Route exact path="/" element={<HomePage />}></Route>
            </Route>
            <Route exact path="/news" >
                <Route index element={<NewsPage title="News" pageID={1} />}></Route>
                <Route path=":slug" element={<InnerPage postType="posts" />}></Route>
            </Route>
            <Route exact path="/services">
                <Route index element={<Navigate to="/#services" />}></Route>
                <Route path=":category">
                    <Route index element={<ServicePage title="Service" pageID={1} />}></Route>
                    <Route path=":slug" element={<InnerPage postType="services" />}></Route>
                </Route>
            </Route>
            <Route exact path="/career">
                <Route index element={<CareerPage title="News" pageID={1} />}></Route>
                <Route path=":slug" element={<InnerPage postType="jobs" />}></Route>
            </Route>
            <Route exact path="/focus">
                <Route index element={<FocusPage title="Focus" pageID={1} />}></Route>
            </Route>
            <Route path="*">
                <Route path="*" element={<NotFoundPage />}></Route>
            </Route>
        </Routes>
    );
}

export default MyRoutes;