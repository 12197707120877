import React from "react";

import classes from "./FooterDisclaimer.module.scss";

const FooterDisclaimer = (props) => {
    return(
        <div className={classes.text}>
            {props.children}
        </div>
    );
}

export default FooterDisclaimer;