import React, { useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "../../common/useFetch";
import { menuItems } from "../../config/menuItems";
import SubMenu from "./SubMenu/SubMenu";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";

import classes from "./MobileNavMenu.module.scss";

const MenuItems = (props) => {
    const [subMenuShown, setSubMenuShown] = useState(false);
    const [servicesItems, isLoadedServices] = useFetch('/wp-json/wp/v2/menu?slug=services-sub-menu');

    const handleSubMenu = () => {
        setSubMenuShown(!subMenuShown);
    }

    return(
        <ul className={classes.navList}>
            <li onClick={props.onClick}>
                <Link to="/">Home</Link>
            </li>
            {
                menuItems.map((el, index) => (
                    !el.submenu
                    ? <li
                        key={index}
                        onClick={props.onClick}
                    >
                        <Link to={el.url}>{el.title}</Link>
                    </li> 
                    : (el.submenu && el.title === 'Services') && <li
                        key={index}
                        className={classes.hasSubMenu}
                        onClick={handleSubMenu}
                    >
                        <span>
                            {el.title}
                            <Arrow />
                        </span>
                        {   
                            isLoadedServices &&
                                <SubMenu
                                    items={servicesItems.data[0].acf.inner_menu_items} 
                                    closeMenu={props.onClick}
                                    closeSubMenu={handleSubMenu}
                                    parentUrl={el.url}
                                    parentTitle={el.title}
                                    isActive={subMenuShown}
                                />
                        }
                    </li> 
                ))
            }
        </ul>
    );
}

export default MenuItems