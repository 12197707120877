const IconLinkedIn = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.48C5.6376 0.48 0.480003 5.6376 0.480003 12C0.480003 18.3624 5.6376 23.52 12 23.52C18.3624 23.52 23.52 18.3624 23.52 12C23.52 5.6376 18.3624 0.48 12 0.48ZM9.18 16.7748H6.8472V9.2676H9.18V16.7748ZM7.9992 8.346C7.2624 8.346 6.786 7.824 6.786 7.1784C6.786 6.5196 7.2768 6.0132 8.0292 6.0132C8.7816 6.0132 9.2424 6.5196 9.2568 7.1784C9.2568 7.824 8.7816 8.346 7.9992 8.346ZM17.7 16.7748H15.3672V12.6144C15.3672 11.646 15.0288 10.9884 14.1852 10.9884C13.5408 10.9884 13.158 11.4336 12.9888 11.862C12.9264 12.0144 12.9108 12.2304 12.9108 12.4452V16.7736H10.5768V11.6616C10.5768 10.7244 10.5468 9.9408 10.5156 9.2664H12.5424L12.6492 10.3092H12.696C13.0032 9.8196 13.7556 9.0972 15.0144 9.0972C16.5492 9.0972 17.7 10.1256 17.7 12.336V16.7748Z" fill="#07426F"/>
    </svg>
`;

const IconFacebook = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.48C5.6376 0.48 0.480003 5.6376 0.480003 12C0.480003 18.3624 5.6376 23.52 12 23.52C18.3624 23.52 23.52 18.3624 23.52 12C23.52 5.6376 18.3624 0.48 12 0.48ZM14.7288 8.4408H12.9972C12.792 8.4408 12.564 8.7108 12.564 9.0696V10.32H14.73L14.4024 12.1032H12.564V17.4564H10.5204V12.1032H8.6664V10.32H10.5204V9.2712C10.5204 7.7664 11.5644 6.5436 12.9972 6.5436H14.7288V8.4408Z" fill="#07426F"/>
    </svg>
`;

export const socialsConfig = {
    email: "info@udelphi.com",
    socials: [
        {
            icon: IconLinkedIn,
            link: "https://www.linkedin.com/company/delphi-software",
        },
        {
            icon: IconFacebook,
            link: "https://www.facebook.com/DelphiSoftwareCompany/",
        },
    ]
};