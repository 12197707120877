import React from "react";
import { Link } from "react-router-dom";

import classes from "./SubMenu.module.scss";

const SubMenu = (props) => {
    const { isActive } = props;

    return(
        <div
            className={classes.subMenu}
            data-active={isActive}
        >
            <button className={classes.return} onClick={props.closeSubMenu}>Go Back</button>
            <ul>
                <li onClick={props.closeMenu}>
                    <Link to={props.parentUrl}>{props.parentTitle}</Link>
                </li>
                {
                    props.items.map((item, index) => (
                        <li key={index} onClick={props.closeMenu}>
                            <Link to={item.path}>{item.name}</Link>
                        </li>
                    ))
                }
            </ul>
        </div>        
    )
}

export default SubMenu;