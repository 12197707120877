import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterContacts from "../../Components/Footer/FooterContacts/FooterContacts";
import FooterDisclaimer from "../../Components/Footer/FooterDisclaimer/FooterDisclaimer";
import FooterLogo from "../../Components/Footer/FooterLogo/FooterLogo";
import FooterNav from "../../Components/Footer/FooterNav/FooterNav";
import ScrollUp from "../../Components/UI/ScrollUp/ScrollUp";
import { footerContent } from "../../config/content";
import { footerMenuItems } from "../../config/footerMenuItems";

import classes from "./Footer.module.scss";

const Footer = ({props}) => {
    const location = useLocation();
    const [isBottom, setBottom] = useState(false);
    const [showScrollTop, setShowScrollTop] = useState(false);

    useEffect(() => {
        const footer = document.getElementById('footer') ;
        const footerHeight = footer.clientHeight;
        const scrollPoint = document.body.clientHeight / 2 - 200;

        document.querySelector('.main-wrapper').style.paddingBottom = footerHeight + 'px';

        const handleScroll = () => {
            if(scrollPoint > 0) {
                setBottom(window.pageYOffset > scrollPoint);
            }

            setShowScrollTop(window.pageYOffset > 200);
        };
        
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", handleScroll);

            return () => {
                // Cleanup the event listener
                window.removeEventListener("scroll", handleScroll);
            }
        }
    }, [location]);

    return(
        <>
            <footer 
                className={[classes.footer, isBottom ? classes.active : null].join(' ')}
                id="footer"
            >
                <div className="wrapper">
                    <div className={classes.container}>
                        <div className={classes.logo}>
                            <FooterLogo />
                        </div>
                        <div className={classes.nav}>
                            <FooterNav navItems={footerMenuItems} />
                        </div>
                        <div className={classes.contacts}>
                            <FooterContacts />
                        </div>
                    </div>
                    <div className={classes.disclaimer}>
                        <FooterDisclaimer>{footerContent.disclaimer}</FooterDisclaimer>
                    </div>
                </div>
            </footer>
            { 
                showScrollTop && 
                    <ScrollUp />
            }
        </>
    );
}

export default Footer;