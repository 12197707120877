import React from "react";
import { useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import CategoryHead from "../../Components/CategoryHead/CategoryHead";
import NewsList from "../../Components/NewsList/NewsList";

const FocusPage = (props) => {
    const [searchParams] = useSearchParams();
    const pageID = searchParams.get('page');
    const queryString = [
        `page=${pageID ? pageID : props.pageID}`,
        `&per_page=${1}`,
    ].join('');
    

    return(
        <div className="wrapper page">
            <Breadcrumbs showCrumb={props.showCrumb} />
            <div className="flex-container">
                <div className="flex-xl-5 flex-md-12">
                    <CategoryHead
                        title="Focus"
                        description="Choose your reality"
                    >
                        <img src={require('../../assets/images/imageFocusHead.svg').default} alt="Focus"/>
                    </CategoryHead>
                </div>
                <div className="flex-xl-7 flex-md-12">
                    <NewsList
                        postsParams={{
                            pageID: pageID ? pageID : props.pageID,
                            postType: "focus",
                            navSlug: "focus",
                        }}
                        queryString={queryString}
                    />
                </div>
            </div>
        </div>
    );
}

export default FocusPage;