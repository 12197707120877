import React from "react";
import useFetch from "../../common/useFetch";
import SectionHead from "../SectionHead/SectionHead";
import SliderJobs from "../Slider/SliderJobs";

import classes from "./SectionJobs.module.scss";

const SectionJobs = (props) => {
    const [posts, isLoaded] = useFetch('/wp-json/wp/v2/jobs?per_page=32&page=1');

    return(
        <section className={classes.section}>
            <div className="wrapper wrapper--medium">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <SectionHead
                            title="Careers"
                            subtitle="Join To Our Team"
                        />
                    </div>
                    <div className="flex-xl-12">
                        {
                            isLoaded
                            ? <SliderJobs 
                                sliderItems={posts.data}
                            /> : null
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionJobs;