import React from "react";
import { Link } from "react-router-dom";

import classes from "./FooterNav.module.scss";

const FooterNav = (props) => {
    const {navItems} = props;

    return(
        <nav className={classes.nav}>
            {
                navItems.map((item, index) => (
                        <ul key={index} className={classes.nav__item}>
                            {
                                item.map((subItem, subIndex) => (
                                    <li key={subIndex}>
                                        <Link to={subItem.url}>{subItem.title}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    )
                )
            }
        </nav>
    );
}

export default FooterNav;