import React from "react";
import usePagination from "../../common/usePagination";

import classes from "./LoadMore.module.scss";

const LoadMore = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (
        parseInt(currentPage) === 0
        || paginationRange.length < 2
        || parseInt(currentPage) === parseInt(totalCount) 
    ) {
        return null;
    }

    const onNext = () => {
        onPageChange(parseInt(currentPage) + 1);
    };

    return(
        <button
            className={classes.button}
            onClick={onNext}
        >More</button>
    )
}

export default LoadMore;