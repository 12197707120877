import React, { useContext, useEffect, useState } from "react";
import { AppStore } from "../../common/appStore";
import HeaderContacts from "../../Components/Header/HeaderContacts/HeaderContacts";
import HeaderLogo from "../../Components/Header/HeaderLogo/HeaderLogo";
import MobileNavMenu from "../../Components/MobileNavMenu/MobileNavMenu";
import NavMenu from "../../Components/NavMenu/NavMenu";
import Hamburger from "../../Components/UI/Hamburger/Hamburger";

import classes from "./Header.module.scss";

let lastScrollTop = 0;

const Header = (props) => {
    const [isScrolled, setScrolled] = useState(false);
    const [isScrolledToTop, setScrolledToTop] = useState(true);
    const [isMobileNavVisible, setMobileNavVisible] = useState(false);
    const { isMobile, setMobileNavOpen } = useContext(AppStore);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.pageYOffset > 30);

            
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > lastScrollTop){
                setScrolledToTop(false);
            } else {
                setScrolledToTop(true);
            }
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
            
        };
        
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", handleScroll);

            return () => {
                // Cleanup the event listener
                window.removeEventListener("scroll", handleScroll);
            }
        }
    }, []);

    useEffect(() => {
        if(!isMobile) {
            setMobileNavOpen(false);
        } else {
            if(isMobileNavVisible) {
                setMobileNavOpen(true);
            } else {
                setMobileNavOpen(false);
            }
        }
    }, [isMobile, isMobileNavVisible, setMobileNavOpen])

    const handleHamburgerClick = () => {
        setMobileNavVisible(true);
    }

    const handleCloseClick = () => {
        setMobileNavVisible(false);
    }

    return(
        <header className={[
            classes.Header,
            isScrolled ? classes.scrolled : '',
            ( isScrolledToTop ) ? classes.toTop : classes.toBottom
        ].join(' ')}>
            <div className="wrapper">
                <div className={classes.container}>
                    {
                        isMobile
                        ? <div className={classes.hamburger}>
                            <Hamburger handleClick={handleHamburgerClick}/>
                        </div>
                        : null
                    }
                    <div className={classes.logo}>
                        <HeaderLogo />
                    </div>
                    <div className={classes.headerLeft}>
                        <div className={classes.nav}>
                            { !isMobile ? <NavMenu /> : null}
                        </div>
                        <div className={classes.contacts}>
                            <HeaderContacts isMobile={isMobile} />
                        </div>
                    </div>
                </div>
            </div>
            {
                isMobile
                ? <MobileNavMenu
                    handleClose={handleCloseClick}
                    isActive={isMobileNavVisible}
                />
                : null
            }
        </header>
    );
}

export default Header;