import React from "react";
import useFetch from "../../common/useFetch";
import SectionHead from "../SectionHead/SectionHead";
import Slider from "../Slider/Slider";

import classes from "./SectionNews.module.scss";

const SectionNews = (props) => {
    const [posts, isLoaded] = useFetch('/wp-json/wp/v2/posts?per_page=7&page=1', 'my-posts');
    return(
        <section className={classes.section}>
            <div className="wrapper wrapper--long">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <SectionHead
                            title="Latest news"
                            subtitle="Read Latest Updates"
                        />
                    </div>
                    <div className="flex-xl-12">
                        {
                            isLoaded
                            ? <Slider sliderItems={posts.data} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionNews;