import React from "react";
import { socialsConfig } from "../../config/socialsConfig";
import MyLink from "../UI/MyLink/MyLink";

import classes from "./SocialsNav.module.scss";

const SocialsNav = (props) => {
    return (
        <ul className={[
            classes.nav,
            (props.skin === "white") ? classes.nav__white : '',
            ].join(' ')}>
            {
                socialsConfig.socials.map((item, index) => {
                    return(
                        <li key={index}>
                            <MyLink
                                href={item.link}
                                target="_blank"
                                linkType="socialType"
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                            >
                            </MyLink>
                        </li>
                    );
                })
            }
        </ul>
    );
}

export default SocialsNav;