import React from "react";
import useFetch from "../../common/useFetch";
import SectionHead from "../SectionHead/SectionHead";
import ServicesList from "../ServicesList/ServicesList";

import classes from "./SectionServices.module.scss";

const SectionServices = (props) => {
    const [categories, isLoaded] = useFetch('wp-json/wp/v2/categories?per_page=100');

    return(
        <section className={[classes.section, props.page && classes.page].join(' ')} id="services">
            <div className="wrapper">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <SectionHead
                            title="Our Services"
                            subtitle="We provide you with high quality IT Solution services"
                        />
                    </div>
                    <div className="flex-xl-12">
                        {
                            isLoaded
                            ? <ServicesList items={categories.data} />
                            : null
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionServices;