import React from "react";
import { ReactComponent as Arrow } from "../../../assets/images/arrowNext.svg";

import classes from "./ScrollUp.module.scss";

const ScrollUp = (props) => {
    const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }

    return(
        <button
            type="button"
            className={classes.btn}
            onClick={handleClick}
        >
            <Arrow />
        </button>
    );
}

export default ScrollUp;