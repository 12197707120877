import React, { useEffect, useMemo, useState } from "react";
import { Swiper, Pagination } from "swiper";
import SlideJobs from "./Slide/SlideJobs";

import "swiper/css";
import "swiper/css/pagination";
import classes from "./Slider.module.scss";
import SliderFilter from "./SliderFilter/SliderFilter";
import { getUnicArray } from "../../common/helpers";

const SliderJobs = ({sliderItems}) => {
    Swiper.use([Pagination]);

    const [filterOptions, setFilterOptions] = useState(['All']);
    const [activeFilter, setActiveFitler] = useState('All');
    const [mySwiper, setMySwiper] = useState(null);
    const swiperConf = useMemo(() => {
        return {
            loop: true,
            slidesPerView: 1,
            slidesPerGroup: 1,
            centeredSlides: false,
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                992: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
        }
    }, [])

    useEffect(() => {
        setMySwiper(new Swiper('.swiperContainer', swiperConf)) ;
    }, [swiperConf]);

    useEffect(() => {
        const citiesArr = []

        sliderItems.forEach((post) => {
            citiesArr.push(post.acf.city.name);
        });

        const unicCities = getUnicArray(citiesArr);

        unicCities.forEach((city) => {
            setFilterOptions(filterOptions => [...filterOptions, city]);
        });
    }, [sliderItems]);

    const handleFilter = (value) => {
        const filter = value;
        const filteredSlides = document.querySelectorAll("[data-filter]");

        if(filter === "All") {
            filteredSlides.forEach(slide => {
                slide.classList.remove("non-swpier-slide");
                slide.classList.add("swiper-slide");
                slide.style.display = "block";
            });

            mySwiper.destroy();
            setMySwiper(new Swiper('.swiperContainer', swiperConf));
        } else {
            filteredSlides.forEach(slide => {
                const filterOption = slide.getAttribute("data-filter");

                if(filterOption !== filter) {
                    slide.classList.remove("swiper-slide");
                    slide.classList.add("non-swiper-slide");
                    slide.style.display = "none";
                } else {
                    slide.classList.remove("non-swiper-slide");
                    slide.classList.add("swiper-slide");
                    slide.style.display = "block";
                }
            });
            mySwiper.destroy();
            setMySwiper(new Swiper('.swiperContainer', swiperConf));
        }

        setActiveFitler(value);
    }

    return(
        <div className={[classes.Slider, classes.sliderCustom].join(' ')}>
            <SliderFilter
                filters={filterOptions}
                activeFilter={activeFilter}
                handleFilter={handleFilter}
            />
            <div className="swiper swiperContainer">
                <div className="swiper-wrapper">
                    {
                        sliderItems.map((post) => {
                            return(
                                <div key={post.id} className="swiper-slide" data-filter={post.acf.city.name}>                        
                                    <SlideJobs
                                        type="job"
                                        title={post.title.rendered}
                                        text={post.excerpt.rendered}
                                        location={post.acf.city.name}
                                        link={{
                                            url: `/career/${post.slug}`,
                                            target: "_self",
                                            rel: "",
                                        }}
                                    />
                                </div> 
                            )      
                        })
                    }
                </div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    )
}

export default SliderJobs;