import React from "react";
import { Link } from "react-router-dom";
import Image from "../../UI/Image/Image";

import classes from "./ImageMenu.module.scss";

const ImageMenu = ({menu, isShown}) => {
    return(
        <ul className={[classes.imageMenu, isShown ? classes.shown : ''].join(' ')}>
            {
                menu.map((item, index) => {
                    return(
                        <li key={index}>
                            <Link to={item.path}>
                                <Image outterClass={classes.imgWrapper} src={item.image} />
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    )
                })
            }
        </ul>
    );
}

export default ImageMenu;