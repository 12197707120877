import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Image from "../UI/Image/Image";
import MyLink from "../UI/MyLink/MyLink";

import "swiper/css";
import classes from "./PartnersSlider.module.scss";

const PartnersSlider = (props) => {
    const { partners } = props;

    return (
        <div className={classes.slider}>
            <Swiper
                slidesPerView={3}
                spaceBetween={40}
                loop={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 50,
                    },
                    992: {
                        slidesPerView: 6,
                        spaceBetween: 60,
                    },
                }}
                modules={[Autoplay]}
            >
                {
                    partners.map((item, index) => {
                        return(
                            <SwiperSlide key={index}>
                                <MyLink
                                    outterClasses={classes.sliderLink}
                                    href={item.link ? item.link : null}
                                    target="_blank"
                                >
                                    <Image
                                        outterClass={classes.imgWrapper}
                                        src={item.image}
                                    />
                                </MyLink>
                            </SwiperSlide>
                        );
                    })
                }
            </Swiper>
        </div>
    )
}

export default PartnersSlider;