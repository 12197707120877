import React from "react";
import TextBox from "../../UI/TextBox/TextBox";

import classes from "./FocusItem.module.scss";

const FocusItem = ({postData}) => {
    return(
        <article className={classes.item}>
            <TextBox>
                <div dangerouslySetInnerHTML={{ __html: postData.content.rendered }}></div>
            </TextBox>
        </article>
    );
}

export default FocusItem;