import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppStore } from "../../common/appStore";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";

import classes from "./Breadcrumbs.module.scss";

const Breadcrumbs = (props) => {
    const location = useLocation();
    const [pathnames, setPathnames] = useState([]);
    const { isMobile } = useContext(AppStore);

    useEffect(() => {
        setPathnames(location.pathname.split("/").filter(x => x));
    }, [location]);

    return(
        <ul className={classes.breadcrumbs}>
            {
                pathnames.length > 0 ? (
                    <li>
                        <Link to="/">Home</Link>
                        <Arrow />
                    </li>
                ) : null
            }
            {
                pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                    const isLast = index === pathnames.length - 1;

                    return  isLast ? (
                        <li 
                            key={name} 
                        >
                            <span dangerouslySetInnerHTML={{ __html: props.lastTitle ? props.lastTitle : name }}></span>
                        </li>
                    ) : (
                        isMobile && name === "services" && !props.showPostType
                        ? null 
                        : <li key={name}>
                            <Link to={routeTo}>{name}</Link>
                            <Arrow />
                        </li>
                    )
                })
            }
        </ul>
    );
}

export default Breadcrumbs;