import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import classes from "./SectionHeroSlider.module.scss";
import { heroContent } from "../../../config/content";

const SectionHeroSlider = (props) => {
    return(
        <div className={classes.slider}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                effect={"fade"}
                // autoplay={{
                //     delay: 5000,
                //     disableOnInteraction: false,
                // }}
                modules={[Autoplay, EffectFade]}
            >
                <SwiperSlide>
                    <div className={classes.bg}>
                        <img src={heroContent.background} alt="img"/>
                    </div>
                    <div className={classes.img}>
                        <img src={heroContent.image} alt="img"/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default SectionHeroSlider;