import React from "react";

import classes from "./SliderFilter.module.scss";

const SliderFilter = ({filters, handleFilter, activeFilter}) => {
    return(
        <div className={classes.filter}>
            {
                filters.map(element => {
                    return(
                        <button                        
                            key={element}
                            className={classes.filterButton}
                            data-active={activeFilter === element ? true : false}
                            onClick={() => handleFilter(element)}
                        >
                            {element}
                        </button>
                    )
                })
            }
        </div>
    );
}

export default SliderFilter;