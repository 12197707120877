import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Thumb from "../../Components/UI/Thumb/Thumb";
import TitleBox from "../../Components/UI/TitleBox/TitleBox";
import TextBox from "../../Components/UI/TextBox/TextBox";
import useFetchPost from "../../common/useFetchPost";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AppStore } from "../../common/appStore";

const InnerPage = (props) => {
    const {postType} = props;
    const pathname = useParams();
    const location = useLocation();
    const [postData, isLoaded] = useFetchPost(`/wp-json/wp/v2/${postType}?slug=${pathname.slug}`);
    const { isMobile } = useContext(AppStore);
    const navigate = useNavigate();

    useEffect(() => {
        if(isLoaded && postData.data.length === 0) {
            navigate("/404");
        }
    }, [isLoaded, postData, navigate])

    return(
        <div className="wrapper page">
            {
                (isLoaded && postData.data.length > 0) ? (
                    <>
                        <Helmet>
                            <title>Delphi - {postData.data[0].title.rendered}</title>
                            <link rel="canonical" href={`https://www.udelphi.com${location.pathname}`} />
                        </Helmet>
                        <Breadcrumbs lastTitle={postData.data[0].title.rendered} />
                        <div className="flex-container">
                            {
                                (postData.data[0].acf.thumbnail && !isMobile)
                                ? <div className="flex-xl-4 flex-md-12">
                                    <Thumb
                                        imageSrc={
                                            (postData.data[0].acf.thumbnail.sizes && postData.data[0].acf.thumbnail.sizes.post_thumb)
                                            ? postData.data[0].acf.thumbnail.sizes.post_thumb
                                            : postData.data[0].acf.thumbnail.url
                                        }
                                        alt={postData.data[0].acf.thumbnail.alt}
                                    />
                                </div> : null
                            }
                            <div className="flex-xl-8 flex-md-12">
                                <TitleBox
                                    title={postData.data[0].title.rendered}
                                    subtitle={postData.data[0].excerpt.rendered}
                                    location={
                                        postData.data[0].acf.city
                                        ? postData.data[0].acf.city.name
                                        : null
                                    }
                                />
                                {
                                    (postData.data[0].acf.thumbnail && isMobile)
                                    ? <Thumb
                                        imageSrc={
                                            (postData.data[0].acf.thumbnail.sizes && postData.data[0].acf.thumbnail.sizes.post_thumb)
                                            ? postData.data[0].acf.thumbnail.sizes.post_thumb
                                            : postData.data[0].acf.thumbnail.url
                                        }
                                        alt={postData.data[0].acf.thumbnail.alt}
                                    /> : null
                                }
                                <TextBox>
                                    <div dangerouslySetInnerHTML={{ __html: postData.data[0].content.rendered }}></div>
                                </TextBox>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    );
}

export default InnerPage;