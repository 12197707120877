import React from "react";
import { socialsConfig } from "../../../config/socialsConfig";
import SocialsNav from "../../SocialsNav/SocialsNav";
import MyLink from "../../UI/MyLink/MyLink";

import classes from "./HeaderContacts.module.scss";

const HeaderContacts = (props) => {
    return (
        <div className={classes.HeaderContacts}>
            {
                !props.isMobile
                ? <MyLink
                    href={`mailto:${socialsConfig.email}`}
                    linkType="emailType"
                    outterClasses={classes.email}
                >{socialsConfig.email}</MyLink>
                : null                
            }
            <SocialsNav />
        </div>
    );
}

export default HeaderContacts;