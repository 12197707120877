import React from "react";
import MenuItems from "./MenuItems";


import classes from "./MobileNavMenu.module.scss";

const MobileNavMenu = (props) => {
    const { isActive, menuItems } = props;

    return(
        <nav
            className={classes.navMobile}
            data-active={isActive}
        >
            <button onClick={props.handleClose}>
                <span></span>
                <span></span>
            </button>
            <MenuItems
                onClick={props.handleClose}
                items={menuItems}
            />
        </nav>
    );
}

export default MobileNavMenu;