import { React, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.scss';
import { AppStore } from './common/appStore';
import { handleBodyScroll } from './common/helpers';
import useIsMobile from './common/useIsMobile';
import Layout from './hoc/Layout/Layout';

function App() {
    const isMobile = useIsMobile();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    useEffect(() => {
        handleBodyScroll(isMobileNavOpen);
    }, [isMobileNavOpen])

    return (
        <AppStore.Provider
            value={
                {
                    isMobile,
                    setMobileNavOpen
                }
            }
        >
            <div
                className="App"
                data-scrollblocked={isMobileNavOpen ? true : false}
            >
                <BrowserRouter>
                    <Layout />
                </BrowserRouter>
            </div>
        </AppStore.Provider>
    );
}

export default App;
