import React from "react";
import { socialsConfig } from "../../../config/socialsConfig";
import SocialsNav from "../../SocialsNav/SocialsNav";
import MyLink from "../../UI/MyLink/MyLink";
import { ReactComponent as Icon } from "../../../assets/images/iconEmailLetter.svg";

import classes from "./FooterContacts.module.scss";

const FooterContacts = (props) => {
    return (
        <div className={classes.contacts}>
            <SocialsNav skin="white" />
            <MyLink
                href={`mailto:${socialsConfig.email}`}
                linkType="emailType"
                outterClasses={classes.email}
            >
                <Icon />
                {socialsConfig.email}
            </MyLink>
        </div>
    );
}

export default FooterContacts;