import React from "react";
import { aboutContent } from "../../config/content";
import Infographic from "../Infographic/Infographic";
import SectionHead from "../SectionHead/SectionHead";
import Image from "../UI/Image/Image";
import TextBox from "../UI/TextBox/TextBox";

import classes from "./SectionAbout.module.scss";

const SectionAbout = (props) => {
    return(
        <section className={classes.section} id="about">
            <div className="wrapper">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <SectionHead
                            title={aboutContent.title}
                            subtitle={aboutContent.subtitle}
                        />
                    </div>
                    <div className="flex-xl-5 flex-md-12">
                        <Image
                            src={require('../../assets/images/imageAbout.svg').default}
                            alt={aboutContent.title}
                            outterClass={classes.imgWrapper}
                        />
                    </div>
                    <div className="flex-xl-7 flex-md-12">
                        <TextBox>
                            <div dangerouslySetInnerHTML={{ __html: aboutContent.text }}></div>
                        </TextBox>
                    </div>
                    <div className="flex-xl-12">
                        <div className={classes.infographic}>
                            <Infographic items={aboutContent.infoGraphic} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionAbout;