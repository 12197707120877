import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppStore } from "../../common/appStore";
import useFetch from "../../common/useFetch";
import myAxios from "../../config/axiosConfig";
import LoadMore from "../LoadMore/LoadMore";
import Pagination from "../Pagination/Pagination";
import Loader from "../UI/Loader/Loader";
import FocusItem from "./FocusItem/FocusItem";
import NewsItem from "./NewsItem/NewsItem";

import classes from "./NewsList.module.scss";

const pageSize = 1;

const NewsList = ({postsParams, queryString, ...props}) => {
    const [loadedPosts, isLoaded] = useFetch(`/wp-json/wp/v2/${postsParams.postType}?${queryString}`);
    const [showLoader, setShowLoader] = useState(true);
    const [posts, setPosts] = useState(null);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const {isMobile} = useContext(AppStore);
    const navigate = useNavigate();

    useEffect(() => {
        if(postsParams.postType === 'focus') {
            setPerPage(1)
        } else {
            setPerPage(5);
        }
    }, [postsParams.postType])

    useEffect(() => {
        if(isLoaded) {
            setPosts(loadedPosts.data);
            setPage(1);
            setShowLoader(false);
        }
    }, [loadedPosts, isLoaded]);

    const handlePageChange = (page) => {
        navigate(`/${postsParams.navSlug}?page=${page}`);
        setShowLoader(true);
    }

    const handleLoadMore = (page) => {
        setShowLoader(true);
        myAxios.get(`/wp-json/wp/v2/${postsParams.postType}?page=${page}&per_page=${perPage}${postsParams.categoryID ? `&categories=${postsParams.categoryID}`: ''}`)
            .then((res) => {
                setPosts((prevTitles) => {
                    return [...new Set([...prevTitles, ...res.data.map((b) => b)])];
                });
                setPage((prevPageNumber) => prevPageNumber + 1);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err);
            })
    } 

    return(
        isLoaded && (
            <>
                <div className={classes.list}>
                    {
                        posts !== null ? posts.map((post) => {
                            return(
                                postsParams.postType !== 'focus'
                                ? <NewsItem
                                    key={post.id}
                                    link={`/${postsParams.navSlug}/${post.slug}`}
                                    image={post.acf.thumbnail ? post.acf.thumbnail : null}
                                    title={post.title.rendered}
                                    subtitle={post.excerpt.rendered}
                                    iconThumb={props.iconThumb}
                                    city={post.acf.city ? post.acf.city.name : null}
                                />
                                : <FocusItem key={post.id} postData={post} />
                            )
                        }) : null
                    }
                    {
                        showLoader && <Loader />
                    }
                </div>
                {
                    isMobile
                    ? <LoadMore
                        currentPage={page}
                        totalCount={loadedPosts.headers['x-wp-totalpages']}
                        pageSize={pageSize}
                        onPageChange={(page) => handleLoadMore(page)}
                    />
                    : <Pagination
                        currentPage={postsParams.pageID}
                        totalCount={loadedPosts.headers['x-wp-totalpages']}
                        pageSize={pageSize}
                        onPageChange={(page) => handlePageChange(page)}
                    />
                }
            </>
        )   
    );
}

export default NewsList;