import React from "react";
import CategorySubtitle from "../UI/CategorySubtitle/CategorySubtitle";
import CategoryTitle from "../UI/CategoryTitle/CategoryTitle";
import Image from "../UI/Image/Image";

import classes from "./CategoryHead.module.scss";

const CategoryHead = ({title, description, imageSrc, children}) => {
    return(
        <div className={classes.head}>
            <div className={classes.text}>
                <div className={classes.title}>
                    <CategoryTitle>{title}</CategoryTitle>
                </div>
                <div className={classes.subtitle}>
                    <CategorySubtitle>{description}</CategorySubtitle>
                </div>
            </div>
            {
                children
                ? ( <div className={classes.imgWrapper}>{children}</div> )
                : ( <Image
                    outterClass={classes.imgWrapper}
                    src={imageSrc}
                    alt=""
                /> )
            }
        </div>
    )
}

export default CategoryHead;