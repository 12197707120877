import React from "react";

import classes from "./SectionTitle.module.scss";

const SectionTitle = (props) => {
    return(
        <h2 className={classes.title}>{props.children}</h2>
    );
}

export default SectionTitle;