import React from "react";
import { Link } from "react-router-dom";
import Image from "../../UI/Image/Image";
import ReadMoreLink from "../../UI/ReadMoreLink/ReadMoreLink";

import classes from "./Slide.module.scss";

const Slide = (props) => {
    const {image, title, text, link } = props;

    return(
        <Link
            to={link.url}
            target={link.target}
            rel={link.rel}
            className={classes.Slide}
        >
            <Image
                src={
                    (image.sizes && image.sizes.post_thumb_slider)
                    ? image.sizes.post_thumb_slider
                    : image.url
                }
                alt={image.alt}
                outterClass={classes.imgWrapper}
            />
            <div className={classes.content}>
                <h6 className={classes.title}>{title}</h6>
                <div className={classes.text} dangerouslySetInnerHTML={{ __html: text }}></div>
                <ReadMoreLink isFake={true}>Read More</ReadMoreLink>
            </div>
        </Link>
    )
}

export default Slide;