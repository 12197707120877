import { useEffect, useState } from "react";
import myAxios from "../config/axiosConfig";

const useFetchPost = (url) => {
    const [data, setData] = useState(null);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if(url === null) {
            return;
        }

        const loadData = async () => {
            try {
                const response = await myAxios.get(url);
                setData(response);
                setLoaded(true);
            } catch(err) {
                setLoaded(false);
                if(err.response) {
                    // Not on 200 response range
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);

                    if(err.message === "Network Error") {
                        alert('The network connection was lost!');
                    }
                }
            }
        }

        loadData();
    }, [url]);

    return [data, isLoaded];
}

export default useFetchPost;