import React from "react";
import { partnersContent } from "../../config/content";
import PartnersSlider from "../PartnersSlider/PartnersSlider";

import classes from "./SectionPartners.module.scss";

const SectionPartners = (props) => {

    return (
        <section className={classes.section}>
            <div className="wrapper">
                <div className="flex-container">
                    <div className="flex-xl-12">
                        <PartnersSlider partners={partnersContent} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionPartners;