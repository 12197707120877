export const heroContent = {
    title: `Delphi software -<br> inspired by innovations`,
    subtitle: `1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis dui cursus integer mollis urna, consectetur.`,
    image: require("../assets/images/imageHero-1.svg").default,
    background: require("../assets/images/bgHero-1.svg").default,
};

export const partnersContent = [
    {
        image: require("../assets/images/imagePartner-1.png"),
        title: "Parner 1",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-2.png"),
        title: "Parner 2",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-3.png"),
        title: "Parner 3",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-4.png"),
        title: "Parner 4",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-5.png"),
        title: "Parner 5",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-6.png"),
        title: "Parner 6",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-1.png"),
        title: "Parner 7",
        link: false,
    },
    {
        image: require("../assets/images/imagePartner-2.png"),
        title: "Parner 8",
        link: false,
    },
];

export const aboutContent = {
    title: `About`,
    subtitle: `About Our Company`,
    image: require("../assets/images/imageAbout.svg").default,
    text: `
        <p><strong class="blueText">Delphi Software is a boutique provider of high-end R&D Engineering products and services enabling its partners to achieve the competitive edge in a global digital market.</strong></p>
        <p>At Delphi, our relentless focus is on our client success and we pride ourselves on the ability to seamlessly extend our partner’s teams and to create and support truly innovative products that drive our clients’ revenue growth and improve bottom line.</p>
        <p>The constant challenges of accelerated time-to-market, improved product quality, maximized returns on R&D budgets and expansion into new markets continue to grow. Delphi Software expertise will support greater business agility, reducing costs and retaining the competitive advantage.</p>`,
    infoGraphic: [
        {
            image: require("../assets/images/imageInfo-1.svg").default,
            title: `25+`,
            subtitle: `Years Of Experience`,
        },
        {
            image: require("../assets/images/imageInfo-2.svg").default,
            title: `400+`,
            subtitle: `Qualified Engineers`,
        },
        {
            image: require("../assets/images/imageInfo-3.svg").default,
            title: `50+`,
            subtitle: `Happy Clients`,
        },
    ]
};

export const contactsContent = {
    title: `Contact us`,
    subtitle: `Read Latest Updates`,
    image: require('../assets/images/imageContactsForm.svg').default,
    text: `
        <p>
            <strong class="blueText">2 Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong><br/>
            <br/>
            3 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat ridiculus amet sed est placerat vestibulum pretium. Ac aliquet aenean ipsum felis. Auctor morbi nisi, est ultrices et penatibus.
        </p>
    `,
};

export const footerContent = {
    disclaimer: `Delphi Software is a boutique provider of high-end R&D Engineering products and services enabling its partners to achieve the competitive edge in a global digital market.`,
};
