import { useEffect, useState } from "react";

import { configure } from "./configureLocalCaching";

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        if(url === null) {
            return;
        }

        configure().then(async (api) => {
            const response = await api.get(url)
          
            setData(response);
            setLoaded(true);
        }).catch((err) => {
            setLoaded(false);
            if(err.response) {
                // Not on 200 response range
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else {
                console.log(`Error: ${err.message}`);

                if(err.message === "Network Error") {
                    alert('The network connection was lost!');
                }
            }
        });
    }, [url]);

    return [data, isLoaded];
}

export default useFetch;