import React, { useEffect, useState } from "react";

import ContentContainer from "../../Containers/ContentContainer/ContentContainer";
import Header from "../../Containers/Header/Header";
import Footer from "../../Containers/Footer/Footer";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
    const { pathname, hash, key } = useLocation();
    const [displayLocation, setDisplayLoacition] = useState(pathname);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    // useEffect(() => {
    //     if(pathname !== displayLocation) setTransistionStage("fadeOut");
    // }, [pathname, displayLocation]);

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            // if(pathname !== displayLocation) setTransistionStage("fadeOut");
            window.scrollTo({left: 0, top: 0, behavior: 'auto'});
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    window.scrollTo({left: element.offsetLeft, top: element.offsetTop, behavior: 'smooth'});
                    // element.scrollIntoView({behavior: 'smooth'});
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change
    
    return(
        <div
            className={`main-wrapper ${transitionStage}`}
            onAnimationEnd={() => {
                if(transitionStage === "fadeOut") {
                    setTransistionStage("fadeIn");
                    setDisplayLoacition(pathname);
                    window.scrollTo({left: 0, top: 0, behavior: 'auto'});
                }
            }}
        >
            <Header />
            <ContentContainer pathname={displayLocation} />
            <Footer />
        </div>
    );
}

export default Layout;