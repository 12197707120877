import React from "react";

import classes from "./Image.module.scss";

const Image = ({outterClass, alt, ...props}) => {
    return(
        <div className={[classes.imgWrapper, outterClass].join(' ')}>
            <img alt={alt} {...props} />
        </div>  
    );
}

export default Image;