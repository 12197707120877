import React from "react";
import Location from "../Location/Location";

import classes from "./TitleBox.module.scss";

const TitleBox = (props) => {
    const {title, subtitle, location} = props;

    return(
        <div className={classes.titleBox}>
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            {
                location !== null
                ? <div className={classes.location}><Location>{location}</Location></div>
                : null
            }
            <h4 dangerouslySetInnerHTML={{ __html: subtitle }}></h4>
        </div>
    );
}

export default TitleBox;