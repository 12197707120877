import React from "react";
import { ReactComponent as Icon } from "../../../assets/images/iconMapPin.svg";

import classes from "./Location.module.scss";

const Location = (props) => {
    return(
        <div className={classes.Location}>
            <Icon />
            {props.children}
        </div>
    );
}

export default Location;