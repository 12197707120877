import React from "react";

import classes from "./MyLink.module.scss";

const MyLink = ({ linkType, outterClasses, children, isFake, ...props }) => {
    return (
        <a
            className={[classes.link, classes[linkType], outterClasses].join(' ')}
            {...props} >
            {children}
        </a>
    );
}

export default MyLink;