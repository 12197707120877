import { React } from "react";

import classes from "./Button.module.scss";

const Button = (props) => (
    <button
        className={[classes.Button, classes[props.btnType], props.outterClasses].join(' ')}
        onClick={props.clicked}
        {...props}
    >
        {props.children}
    </button>
);

export default Button;